import { create } from 'zustand'

export const useHiddenNav = create<{
  isNavHidden: boolean
  setIsNavHidden: (isNavHidden: boolean) => void
}>((set) => {
  return {
    isNavHidden: false,
    setIsNavHidden: (isNavHidden: boolean) => {
      set({
        isNavHidden
      })
    }
  }
})
